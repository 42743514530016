import { LitElement, html, css } from 'lit-element';
import { Colors, CAUIIcon, CAUIButton, CAUICard, CAUITextInput, CAUIAccordion, CAUIPage, CAUISelector, CAUIButtonGroup, CAUICheckbox } from '../submodules/ui-components'

/**
 * @customElement
 * @polymer
 */
class CaCoursePlanner extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        -webkit-tap-highlight-color: transparent;
        background-color: var(--ca-color-lightgrey);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        touch-action: pan-y;
      }

      :host, :host * {
        box-sizing: border-box;
      }

      main {
        position: relative;
        height: 100%;
        width: 800px;
        max-width: 100%;
        min-height: 100vh;
        margin: 0 auto;
        display: flex;
        box-sizing: border-box;
      }

      ca-ui-page {
        opacity: 0;
        transition: 0.5s opacity, 0.5s transform ease;
        position: absolute;
        top: 16px;
        bottom: 16px;
        left: 0;
        width: 100%;
        pointer-events: none;
      }


      @media(min-width: 800px) {
        ca-ui-page {
          padding-top: 32px;
        }
      }

      ca-ui-page[data-status="future"] {
        display: none;
      }

      ca-ui-page[data-status="active"] {
        opacity: 1;
        pointer-events: all;
        display: block;
      }

      ca-ui-page[data-status="past"] {
        opacity: 0;
      }

      header {
        position: fixed;
        top: 8px;
        width: 800px;
        max-width: 100%;
        margin: auto;
      }

      @media(min-width: 800px) {
        header {
          padding: 16px 0;
        }
      }

      #content {
        width: 100%;
        height: 100%;
      }

      .scrollContainer {
        width: 100%;
        white-space: nowrap;
        display: flex;
      }

      .scrollContainer > ca-ui-selector {
        display: flex;
        width: 100%;
        white-space: normal;
      }

      ca-ui-selector > *:nth-child(1) {
        margin-right: 8px;
      }

      ca-ui-card {
        cursor: pointer;
        user-select: none;
      }

      ca-ui-card.ca-ui-selected {
        --ca-ui-card-fill: var(--ca-gradient-greenblue);
        --ca-ui-card-color: white;
      }

      ca-ui-checkbox {
        margin: 16px 0;
      }

      footer {
        position: fixed;
        bottom: 0;
        width: 800px;
        max-width: 100%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 8px 16px 8px;
        box-sizing: border-box;
      }

      @media(min-width: 800px) {
        footer {
          bottom: 32px;
        }
      }

      #navBack {
        flex: 0;
        --ca-ui-button-fill: var(--ca-color-white);
        --ca-ui-button-text-color: var(--ca-color-green);
      }

      #navForward {
        flex: 1;
      }

      #navBack + #navForward {
        margin-left: 12px;
      }
      
      ca-ui-button-group {
        --ca-ui-button-group-display: grid;
        --ca-ui-button-group-cols: 2;
      }

      @media(min-width: 800px) {
        ca-ui-button-group {
          --ca-ui-button-group-cols: 3;
        }
      }

      #dayPicker {
        --ca-ui-button-group-cols: 4;
      }

      #contactPicker {
        --ca-ui-button-group-cols: 2;
      }

      ca-ui-text-input {
        margin: 24px 0;
      }

      ca-ui-accordion {
        margin-bottom: 76px;
      }

      #summaryPage {
        --ca-ui-button-fill: white;
        --ca-ui-button-text-color: black;
        --ca-ui-button-text-align: left;
        --ca-ui-button-border-color: black;
        --ca-ui-button-border-radius: 5px;
      }

      #summaryPage ca-ui-button {
        margin: 8px 0;
      }

      #summaryPage ca-ui-button:active {
        --ca-ui-button-fill: var(--ca-color-lightgrey);
      }

      .flexpage {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      #thankYouPage {
        text-align: center;
        height: 100%;
        --ca-ui-icon-color: red;
        bottom: 0;
        top: 0;
      }

      #thankYouPage[data-status="active"] {
        display: flex;
      }

      #thankYouPage ca-ui-icon {
        --ca-ui-icon-size: 72px;
      }

      #loadingScreen {
        display: flex!important;
        background: var(--ca-ui-main-color);
        z-index: 9;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        transition: 1s transform, 1s opacity;
        transition-delay: 0.4s;
        transform-origin: center;
      }
      
      :host([loaded]) #loadingScreen {
        opacity: 0;
      }

      #intro-logo {
        width: 100%;
        height: 100%;
      }
      
      [hidden] {
        display: none;
      }
    `;
  }

  /**
   * Intro page
   */
  _renderIntroPage() {
    return html`
      <style>
        :host{
          ${Colors}
        }
      </style>
      <ca-ui-page @transitionstart="${this._handlePageTransitionStart}" @transitionend="${this._handlePageTransitionEnd}" data-status="${this._parsePageStatus(this.pageIndexes.welcome)}">

        <h1 slot="header">Geef eenvoudig je voorkeur door</h1>
         
        <article slot="header">
          <p>Goed dat je er bent!</p>
          <p>Bij Con Amore besteden we veel aandacht aan flexibiliteit en service. We willen het je zo eenvoudig mogelijk maken om een traject te vinden wat bij je past. Dit doen we graag persoonlijk en op basis van jouw voorkeuren.</p>
          <p>Het werkt als volgt:</p>
          <ul>
            <li>Geef je wensen door in 5 stappen</li>
            <li>Binnen 1 werkdag word je vrijblijvend geïnformeerd</li>
            <li>Je krijgt een voorstel op maat van onze studiecoaches</li>
          </ul>
        </article>

        <ca-ui-accordion slot="footer" @selected-index-changed="${this._handleAccordionSelectedSlotChanged}">
          <article data-accname="Waar wij het voor doen">Onze missie: Een bijdrage leveren aan een gezondheidszorg waarbij complementaire en reguliere geneeskunde intensief samenwerken. Wij doen dit door zorgprofessionals in hun kracht te zetten middels een hoogwaardige, geaccrediteerde opleiding, waarin we wetenschappelijke kennis combineren met een holistisch mensbeeld.</article>
        </ca-ui-accordion>
        
      </ca-ui-page>
    `
  }

  /**
   * Pick Courses
   */
  _renderCoursePickerPage() {
    return html`
      <ca-ui-page @transitionstart="${this._handlePageTransitionStart}" @transitionend="${this._handlePageTransitionEnd}" data-status="${this._parsePageStatus(this.pageIndexes.course)}">
      
        <h1 slot="header">In welke opleiding(en) heb je interesse?</h1>

        <div class="scrollContainer" slot="body">

          <ca-ui-selector 
            attr-for-selected="data-value"
            multi
            @values-changed="${this._handleSelectedCoursesChanged}"
          >
            ${this.courses.map(course => html`                
              <ca-ui-card
                title="${course.label}"
                image-url="${course.imageUrl || 'http://placehold.it/800x450'}"
                data-value="${course.value}"
              ></ca-ui-card>
            `)}
            </ca-ui-selector>

        </div>

        <ca-ui-checkbox
          slot="body"
          label="Ik wil zo snel mogelijk de opleiding doorlopen"
          @checked-changed="${this._handleExpeditedChanged}"
        ></ca-ui-checkbox>

        <ca-ui-accordion slot="footer" @selected-index-changed="${this._handleAccordionSelectedSlotChanged}">
          <article data-accname="Ik weet het nog niet, help mij kiezen.">De keuze hangt af van je beroep en/of beroepsvereniging. In de regel wordt MBK gebruikt voor somatische beroepen, en PSBK voor beroepen die psychosomatisch werken.</article>
          <article data-accname="Ik heb interesse in beide, kan dat ook?">Zeker, tegenwoordig kiezen steeds meer therapeuten ervoor om beiden opleidingen te volgen, zodat ze een holistische basis voor zichzelf creëren. Als je bij ons een geaccrediteerde opleiding MBK doet, dan kom je in aanmerking voor een verkorte opleiding PSBK, en andersom. Dat scheelt je in totaal 6 lesdagen en je krijgt bovendien een mooie korting. </article>
          <article data-accname="Wat zijn de kosten?">De opleiding bedraagt €1995,- en dit kun je in termijnen betalen zonder extra kosten.</article>
          <article data-accname="Hoe lang duurt de opleiding?">De opleiding bestaat uit 12 klassikale lesdagen. In de regel wordt deze over een jaar verdeeld, echter als je sneller of langzamer wilt studeren dan kan dat ook.</article>
        </ca-ui-accordion>

      </ca-ui-page>

    `
  }

  /**
   * Pick Locations
   */
  _renderLocationPickerPage() {
    return html`

      <ca-ui-page @transitionstart="${this._handlePageTransitionStart}" @transitionend="${this._handlePageTransitionEnd}" data-status="${this._parsePageStatus(this.pageIndexes.location)}">
        
        <h1 slot="header">Welke locaties hebben je voorkeur?</h1>

        <article slot="header">
          <p>We hebben leslocaties door heel het land. Je kunt hier aangeven welke locaties je voorkeur hebben.</p>
          <p>Je kunt één of meerdere locatie(s) selecteren.</p>
        </article>

        <ca-ui-button-group 
          slot="body"
          small
          multi
          .options="${this.locations}"
          @selected-values-changed="${this._handleSelectedLocationsChanged}"
        ></ca-ui-button-group>
        
      </ca-ui-page>

    `
  }

  /**
   * Pick Days
   */
  _renderDayPickerPage() {
    return html`
    
      <ca-ui-page @transitionstart="${this._handlePageTransitionStart}" @transitionend="${this._handlePageTransitionEnd}" data-status="${this._parsePageStatus(this.pageIndexes.dayPreference)}">
        
        <h1 slot="header">Kies je voorkeursdag(en)</h1>
        
        <article slot="header">
          <p>Wil je graag in het weekend les of juist doordeweeks? Of maakt het niet uit?</p>
          <p>Alles is mogelijk, kies hier je voorkeursdagen.</p>
        </article>

        <ca-ui-button-group 
          slot="body"
          id="dayPicker"
          small
          multi
          .options="${this.days}"
          @selected-values-changed="${this._handleSelectedDaysChanged}"
        ></ca-ui-button-group>

        <ca-ui-accordion slot="footer" @selected-index-changed="${this._handleAccordionSelectedSlotChanged}">
          <article data-accname="Zit ik aan deze dagen vast?">
          Nee hoor, op onze website kun je in je eigen dashboard per les aangeven op welke datum en locatie je deze wilt volgen.
          </article>
        </ca-ui-accordion>

      </ca-ui-page>

    `
  }

  /**
   * Summary Page
   */
  _renderSummaryPage() {
    return html`
      <ca-ui-page @transitionstart="${this._handlePageTransitionStart}" @transitionend="${this._handlePageTransitionEnd}"  id="summaryPage" data-status="${this._parsePageStatus(this.pageIndexes.summary)}">
        
        <h1 slot="header">Jouw wensen</h1>
          
        <article slot="header">
          Even op een rij:
        </article>

        <article slot="body">

          <p>
            Je kiest voor de ${this.userCourses && this.userCourses.length > 1 ? 'opleidingen' : 'opleiding'}:<br>
            <ca-ui-button
              .label="${this._readableSelectedCourses || 'Course Placeholder'}"
              icon="pencil"
              small
              @click="${() => this.currentIndex = this.pageIndexes.course}"
            ></ca-ui-button>
          </p>

          <p>
          Deze volg je het liefst op:<br>
          <ca-ui-button
            .label="${this._readableSelectedDays || 'Day Placeholder'}"
            icon="pencil"
            small
            @click="${() => this.currentIndex = this.pageIndexes.dayPreference}"
          ></ca-ui-button>
          </p>

          <p>
          In de buurt van:<br>
          <ca-ui-button
            .label="${this.userLocations.join(', ') || 'Locations Placeholder'}"
            icon="pencil"
            small
            @click="${() => this.currentIndex = this.pageIndexes.location}"
          ></ca-ui-button>
          </p>

        </article>

      </ca-ui-page>
    `
  }
  
  /**
   * Contact Page
   */
  _renderContactPage() {
    return html`
        <ca-ui-page @transitionstart="${this._handlePageTransitionStart}" @transitionend="${this._handlePageTransitionEnd}" data-status="${this._parsePageStatus(this.pageIndexes.contact)}">

        <h1 slot="header">We zijn er bijna</h1>

        <article slot="header">
          Te gek dat je interesse hebt in onze ${this.userCourses && this.userCourses.length > 1 ? 'opleidingen' : 'opleiding'}. Laat je gegevens achter en onze studieplanners gaan direct voor je aan de slag.
        </article>

        <article slot="body">

          <ca-ui-text-input
            .name="${'Naam'}"
            type="text"
            @value-changed="${this._handleNameChanged}"
          ></ca-ui-text-input>

          <ca-ui-text-input
            .name="${'Telefoon'}"
            type="text"
            @value-changed="${this._handlePhoneChanged}"
          ></ca-ui-text-input>

          <ca-ui-text-input
            .name="${'Email'}"
            type="email"
            @value-changed="${this._handleEmailChanged}"
          ></ca-ui-text-input>

          <ca-ui-text-input
            .name="${'Opmerkingen'}"
            type="text"
            @value-changed="${this._handleRemarksChanged}"
          ></ca-ui-text-input>

        </article>

      </ca-ui-page>
    `
  }

  /**
   * Thank you Page
   */
  _renderThankYouPage() {
    return html`
        <ca-ui-page @transitionstart="${this._handlePageTransitionStart}" @transitionend="${this._handlePageTransitionEnd}"  id="thankYouPage" class="flexpage" data-status="${this._parsePageStatus(this.pageIndexes.thankYou)}">

          <ca-ui-icon icon="logo" slot="header"></ca-ui-icon>
          <h1 slot="header">Namens het hele Con Amore team: Bedankt!</h1>

          <article slot="body">
            <p>Onze studiecoaches gaan voor je aan de slag. Binnen één werkdag hoor je van ons.</p>
            <p>Benieuwd naar ons verhaal? Bekijk onze <a target="_blank" href="https://www.youtube.com/watch?v=xJEdJ8Tb5nA">mini documentaire</a> "met liefdevolle aandacht".</p>

            <p>Of wil je <a target="_blank" href="http://blog.conamore.com/">interessante artikelen</a> lezen terwijl je wacht?</p>            
            <ca-ui-button label="Conamore.com" icon="arrow-right" @click="${() => this._openURL('https://conamore.com')}"></ca-ui-button>
          </article>

        </ca-ui-page>
    `
  }

  /**
   * Loading
   */
  _renderloadingScreen() {
    return html`
      <div id="loadingScreen" class="flexpage">
        <ca-ui-icon
          id="intro-logo"
          .icon="${'logo'}"
        ></ca-ui-icon>
      </div>
    `
  }

  /**
   * Footer
   */
  _renderFooter() {
    return html`
      <footer>

      ${this.currentIndex === 0 || this.currentIndex === this.maxIndex  ? html `` : html`
        <ca-ui-button
          raised
          id="navBack" 
          @click="${this._backward}"
          .label="${''}"
          .icon="${'arrow-left'}"
        ></ca-ui-button>            
      `}

        <ca-ui-button 
          raised
          id="navForward"
          ?disabled="${this.forwardButtonDisabled}"
          ?hidden="${this.currentIndex === this.maxIndex}" 
          @click="${this._forward}"
          .label="${this.currentIndex === 0 ? 'Start hier' : 'Volgende'}"
          .icon="${'arrow-right'}"
        ></ca-ui-button>
        
      </footer>
    `
  }

  render() {
    return html`

    ${this._renderloadingScreen()}

    ${this._remoteConfigLoaded ? html`

    <main>
      <header>
        <ca-ui-progress-bar
          .value="${this.currentIndex}"
          .max="${this.maxIndex}"
        ></ca-ui-progress-bar>
      </header>

      <div id="content">
        ${this._renderIntroPage()}
        ${this._renderCoursePickerPage()}
        ${this._renderLocationPickerPage()}
        ${this._renderDayPickerPage()}
        ${this._renderSummaryPage()}
        ${this._renderContactPage()}
        ${this._renderThankYouPage()}
      </div>

      ${this._renderFooter()}
    </main>

    ` : html``}

    `;
  }

  static get properties() {
    return {
      userCourses: {
        type: Array
      },
      userLocations: {
        type: Array
      },
      userDays: {
        type: Array
      },
      userName: {
        type: String
      },
      userPhone: {
        type: String
      },
      userEmail: {
        type: String
      },
      userExpedited: {
        type: Boolean
      },
      copy: {
        type: Object
      },
      pageIndexes: {
        type: Object
      },
      styles: {
        type: Object
      },
      welcomeHeader: {
        type: String
      },
      userRemarks: {
        type: String
      },
      locations: {
        type: Array
      },
      currentIndex: {
        type: Number
      },
      maxIndex: {
        type: Number
      },
      courses: {
        type: Array
      },
      loaded: {
        type: Boolean,
        reflect: true
      },
      _remoteConfigLoaded: {
        type: Boolean
      },
      _contactOptions: {
        type: Array
      },
      _applicationSent: {
        type: Boolean
      }
    };
  }

  constructor() {
    super();
    this._initializeFirebase()
    this.userCourses = [];
    this.userLocations = [];
    this.userDays = [];
    this.userName = '';
    this.userPhone = '';
    this.userEmail = '';    
    this.userExpedited = false;
    this.currentIndex = 0;
    this.welcomeHeader = '';
    this.maxIndex = 6;
    this.locations = [];
    this.courses = [
      {
        label: 'Medische Basiskennis', 
        value: 'MBK',
        imageUrl: 'src/images/MBP.png'
      },
      {
        label: 'Psychosociale Basiskennis', 
        value: 'PSBK',
        imageUrl: 'src/images/PBP.png'
    }];
    this.copy = {};
    this.pageIndexes = {};
    this.styling = {};
    this.days = [];
    this.userRemarks = '';
    this.styles = {};
    this._remoteConfigLoaded = false;
    this._contactOptions = [{label: 'Email mij', value: 'email'}, {label: 'Bel mij', value: 'phone'}];
    this._applicationSent = false;
    this._setValuesFromRemoteConfig()
    this.loaded = false;
  }

  updated(props) {
    if(this._remoteConfigLoaded) this.loaded = true;
    if(props.has('styles')) this._updateStyles();
    if(props.has('currentIndex')) this._handleCurrentIndexChange();
  }

  _initializeFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyCnIYoqJxOHDfc8J9h_a6uhm4oQO5-l3Uw",
      authDomain: "con-amore-course-planner.firebaseapp.com",
      databaseURL: "https://con-amore-course-planner.firebaseio.com",
      projectId: "con-amore-course-planner",
      storageBucket: "con-amore-course-planner.appspot.com",
      messagingSenderId: "407289703808",
      appId: "1:407289703808:web:3d122e0cd20c2a617d2565",
      measurementId: "G-DBNT2FKSFQ"
    };
    
    firebase.initializeApp(firebaseConfig);
    window.FIRESTORE = firebase.firestore();
    window.PERFORMANCE = firebase.performance();
    window.ANALYTICS = firebase.analytics ? firebase.analytics() : {logEvent: () => {}};
    window.REMOTECONFIG = firebase.remoteConfig();
    
    
    // Set default config
    REMOTECONFIG.defaultConfig = ({
      "welcome_header": 'Welkom bij de Con Amore Planner',
      "course_header": 'Welke opleiding wil je doen?',
      "location_header": 'Locatie.',
      "day_preference_header": 'Voorkeursdag.',
      "summary_header": 'Amore!',
      "contact_header": 'Klaar is kees.',
      "welcome_page_index": 0,
      "course_page_index": 1,
      "location_page_index": 2,
      "day_preference_page_index": 3,
      "summary_page_index": 4,
      "contact_page_index": 5,
      "thank_you_page_index": 6,
      "main_color": '{"key": "--ca-ui-main-color", "value": "#E5E5E5"}',
      "day_preference_header": 'Voorkeursdag.',
      "course_header": 'Welke opleiding wil je doen?',
      "location_header": 'Locatie.',
      "summary_header": 'Amore!',
      "welcome_page_index": 0,
      "course_page_index": 1,
      "location_page_index": 2,
      "day_preference_page_index": 3,
      "summary_page_index": 4,
      "main_color": '{"key": "--ca-ui-main-color", "value": "#E5E5E5"}',
      "locations": '["Amsterdam","Eindhoven","Groningen","Rotterdam","Utrecht","Zwolle"]',
      "days": '["Ma","Di","Wo","Do","Vr","Za","Zo"]'
    });
    
  }

  get forwardButtonDisabled() {
    switch(this.currentIndex) {
      case this.pageIndexes.welcome:
        return false;
        break;
      case this.pageIndexes.course:
        return (this.userCourses.length === 0);
        break;
      case this.pageIndexes.location:
        return (this.userLocations.length === 0);
        break;
      case this.pageIndexes.dayPreference:
        return (this.userDays.length === 0);
        break;
      case this.pageIndexes.summary:
        return false;
        break;
      case this.pageIndexes.contact:
        return (!this.userName || (!this.userPhone && !this.userEmail));
        break;
      default:
        return false;
        break;
    }
  }

  async _setValuesFromRemoteConfig() {
    await REMOTECONFIG.fetchAndActivate().then((res) => {} ).catch((err) => {console.warn(err)});
    this.copy.welcomeHeader = REMOTECONFIG.getString('welcome_header');
    this.copy.courseHeader = REMOTECONFIG.getString('course_header');
    this.copy.locationHeader = REMOTECONFIG.getString('location_header');
    this.copy.dayPreferenceHeader = REMOTECONFIG.getString('day_preference_header');
    this.copy.summaryHeader = REMOTECONFIG.getString('summary_header');
    this.copy.contactHeader = REMOTECONFIG.getString('contact_header');
    
    this.pageIndexes.welcome = REMOTECONFIG.getNumber('welcome_page_index');
    this.pageIndexes.course = REMOTECONFIG.getNumber('course_page_index');
    this.pageIndexes.location = REMOTECONFIG.getNumber('location_page_index');
    this.pageIndexes.dayPreference = REMOTECONFIG.getNumber('day_preference_page_index');
    this.pageIndexes.summary = REMOTECONFIG.getNumber('summary_page_index');
    this.pageIndexes.contact = REMOTECONFIG.getNumber('contact_page_index');
    this.pageIndexes.thankYou = REMOTECONFIG.getNumber('thank_you_page_index');

    this.styles = {...this.styles, ...{ "mainColor": JSON.parse(REMOTECONFIG.getString('main_color')) } };

    this._parseConfigArrayToButtonDataByKey(REMOTECONFIG.getString('locations'), 'locations');
    this._parseConfigArrayToButtonDataByKey(REMOTECONFIG.getString('days'), 'days');
    this._remoteConfigLoaded = true;
  }

  _updateStyles() {
    Object.values(this.styles).forEach(style => {
      this.style.setProperty(style.key, style.value)
    })
  }

  _parseConfigArrayToButtonDataByKey(configArrayString, key) {
    const arr = JSON.parse(configArrayString);
    const values = [];
    arr.forEach(value => {
      values.push({label: value, value: value})
    })
    this[key] = values;
  }

  _backward(evt) {
    ANALYTICS.logEvent('step_backwards', {value: this.currentIndex - 1});
    this.currentIndex--
    this._scrollTop();
  }

  _forward(evt) {
    ANALYTICS.logEvent('step_forwards', {value: this.currentIndex + 1});
    this.currentIndex++
    this._scrollTop();
  }

  _scrollTop(top = 0, behavior = "smooth") {
    if(this.scroll) {
      this.scroll({
        top: top,
        behavior: behavior
      });
    } else {
      this.scrollTop = 0;
    }
  }

  _handleSelectedCoursesChanged(evt) {
    this.userCourses = evt.detail.value;
  }

  _handleSelectedLocationsChanged(evt) {
    this.userLocations = evt.detail.value;
  }

  _handleSelectedDaysChanged(evt) {
    this.userDays = evt.detail.value;
  }

  _handleNameChanged(evt) {
    this.userName = evt.detail.value;
  }

  _handleRemarksChanged(evt) {
    this.userRemarks = evt.detail.value;
  }

  _handlePhoneChanged(evt) {
    this.userPhone = evt.detail.value;
  }
  
  _handleEmailChanged(evt) {
    this.userEmail = evt.detail.value;
  }

  get _readableSelectedDays() {
    const days = this.userDays.map(day => {
      let readableDay = '';
      switch (day.toLowerCase()) {
        case 'ma':
          readableDay = 'Maandagen'
          break;
        case 'di':
          readableDay = 'Dinsdagen'
          break;          
        case 'wo':
          readableDay = 'Woensdagen'
          break;
        case 'do':
          readableDay = 'Donderdagen'
          break;
        case 'vr':
          readableDay = 'Vrijdagen'
          break;
        case 'za':
          readableDay = 'Zaterdagen'
          break;          
        case 'zo':
          readableDay = 'Zondagen'
          break;
        default:
          break;
      }
      return readableDay;
    })
    return days.join(', ');
  }

  get _readableSelectedCourses() {
    const courses = this.userCourses.map(day => {
      let readableCourse = '';
      switch (day) {
        case 'MBK':
            readableCourse = 'Medische Basiskennis'
          break;
        case 'PSBK':
            readableCourse = 'Psychosociale Basiskennis'
          break;
      }
      return readableCourse;
    })
    return courses.join(', ');
  }

  _handleCurrentIndexChange() {
    if(this.currentIndex === this.maxIndex) {
      this._sendApplication();
    }
    this._logPageViewEvent();
  }

  _logPageViewEvent() {
    let page = 'page_';
    switch(this.currentIndex) {
      case this.pageIndexes.welcome:
        page = page + 'intro';
        break;
      case this.pageIndexes.course:
        page = page + 'course';
        break;
      case this.pageIndexes.location:
        page = page + 'location';
        break;
      case this.pageIndexes.dayPreference:
        page = page + 'day';
        break;
      case this.pageIndexes.summary:
        page = page + 'summary';
        break;
      case this.pageIndexes.contact:
        page = page + 'contact';
        break;
      case this.maxIndex:
        page = page + 'thankYou';
          break;        
      default:
        return;
        break;
    }
    if(page === 'page_' || !page) return;
    ANALYTICS.logEvent(page);
  }

  _handleAccordionSelectedSlotChanged(evt) {
    // Fires by default and should be ignored.
    if(evt.detail.value === -1) return;

    const articlesArr = [...evt.target.querySelectorAll('article')]
    const copy = articlesArr[evt.detail.value] ? articlesArr[evt.detail.value].dataset['accname'] : null;
    if(!copy) return;
    ANALYTICS.logEvent('open_faq', {value: copy});
  }

  async _sendApplication() {
    if(this._applicationSent === true) return;

    const userInput = {
      courses: this.userCourses,
      locations: this.userLocations,
      days: this.userDays,
      name: this.userName,
      phone: this.userPhone,
      email: this.userEmail,
      expedited: this.userExpedited,
      remarks: this.userRemarks,
      timeCreated: new Date().getTime()
    }

    const application = this._filterEmptyValuesInObj(userInput);
    await FIRESTORE.collection('applications').add(application).catch(err => {
      console.warn(`Error while sending application: ${err}`)
    });
    ANALYTICS.logEvent('send_application');
    
    this._applicationSent = true;
  }

  _filterEmptyValuesInObj(obj) {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }

  _handleExpeditedChanged(evt) {
    this.userExpedited = evt.detail.value;
  }

  _parsePageStatus(id) {
    if(id < this.currentIndex) return 'past';
    if(id === this.currentIndex) return 'active';
    if(id > this.currentIndex) return 'future';
  }

  _openURL(url) {
    ANALYTICS.logEvent('open_link', {value: url});

    const a = document.createElement('a');
    a.href = url;
    a.rel = 'noopener noreferrer';
    a.target= '_blank';
    document.body.appendChild(a);
    a.click();
    window.requestAnimationFrame(() => document.body.removeChild(a));
  }

  _handlePageTransitionStart(evt) {
    const activeEl = this.shadowRoot.querySelector('[data-status="active"]');
    if(this.currentIndex === this.maxIndex) return activeEl.style.display = 'flex';
    activeEl.style.display = 'block';
  }

  _handlePageTransitionEnd(evt) {
    const el = evt.target;
    el.style.display = 'none';
  }


}

window.customElements.define('ca-course-planner', CaCoursePlanner);